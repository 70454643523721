import PropTypes from 'prop-types';
import { Container, Typography } from '@mui/material';
import ErrorResources from '@/components/molecules/error-resources';
import MoreErrorResources from '@/components/molecules/more-error-resources';

function ErrorContent({ title, error, showMoreResources }) {
  return (
    <Container maxWidth="md">
      <Typography variant="h5" component="h1" fontWeight="800" mt={2}>
        {title}
      </Typography>
      {error && (
        <Typography variant="body4" display="block">
          {`${error}: error code`}
        </Typography>
      )}
      <ErrorResources />
      {showMoreResources && (<MoreErrorResources />)}
    </Container>
  );
}

ErrorContent.propTypes = {
  title: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showMoreResources: PropTypes.bool,
};

ErrorContent.defaultProps = {
  error: null,
  showMoreResources: false,
};

export default ErrorContent;
