import { Typography } from '@mui/material';
import ErrorResourceLinkList from '../error-resource-link-list';
import RESOURCES from './constants';

function ErrorResources() {
  return (
    <>
      <Typography mb={2} variant="body4">
        Try these helpful resources instead:
      </Typography>
      <ErrorResourceLinkList resources={RESOURCES} />
    </>
  );
}

export default ErrorResources;
