import PropTypes from 'prop-types';
import { StyledList, StyledLink, StyledLinkText } from '@/components/molecules/error-resource-link-list/ErrorResourceLinkList.styles';

function ErrorResourceLinkList({ resources }) {
  return (
    <StyledList>
      {resources.map((resource) => (
        <li key={resource.value}>
          <StyledLink href={resource.url}>
            <StyledLinkText variant="body4" display="block">
              {resource.value}
            </StyledLinkText>
          </StyledLink>
        </li>
      ))}
    </StyledList>
  );
}

ErrorResourceLinkList.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    url: PropTypes.string,
  })).isRequired,
};

export default ErrorResourceLinkList;
