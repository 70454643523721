import config from '@/helpers/config';

const { careers, ecampus, multimedia, Portal } = config;

const RESOURCES = [
  {
    name: 'Library',
    links: [
      {
        value: 'Student Resources',
        url: `${ecampus.baseUrl}/secure/aapd/Materials/IP/studentresources/index.asp`,
      },
      {
        value: 'Center for Math Excellence',
        url: `${multimedia.baseUrl}/cms/cme`,
      },
      {
        value: 'Center for Writing Excellence',
        url: `${multimedia.baseUrl}/cms/cwe`,
      },
      {
        value: 'Student Workshops',
        url: `${ecampus.baseUrl}/content/MyWorkshops`,
      },
    ],
  },
  {
    name: 'Career',
    links: [
      {
        value: 'Career Navigator',
        url: `${careers.baseUrl}`,
      },
      {
        value: 'Job Explorer',
        url: `${careers.baseUrl}/job-explorer/jobs`,
      },
      {
        value: 'Career Advising',
        url: 'https://phoenix.peoplegrove.com/hub/phoenix/advising/people',
      },
      {
        value: 'Career Resources',
        url: 'https://phoenix.peoplegrove.com/hub/phoenix/resources',
      },
    ],
  },
  {
    name: 'Administration',
    links: [
      {
        value: 'Prior Learning Assessment',
        url: `${ecampus.baseUrl}/content/plac/student/home.aspx`,
      },
      {
        value: 'Order Transcript',
        url: `${ecampus.baseUrl}/Portal/Portal/Public/Login.aspx?ReturnUrl=${encodeURIComponent(`${Portal}/te/transcripts/index.html`)}`,
      },
      {
        value: 'ADA Accommodation',
        url: `${Portal}/content/business/en/ads_appforms/ADA_Request.html`,
      },
    ],
  },
];

export default RESOURCES;
