import { Grid, Typography } from '@mui/material';
import { PaperCard } from '@/components/molecules/more-error-resources/MoreErrorResources.styles';
import ErrorResourceLinkList from '@/components/molecules/error-resource-link-list';
import RESOURCES from './constants';

function MoreErrorResources() {
  return (
    <Grid container spacing={1}>
      {RESOURCES.map((resource) => (
        <Grid item xs={12} sm={4} key={resource.name}>
          <PaperCard elevation={2}>
            <Typography variant="h5" component="h2" fontWeight="800" mb={2}>
              {resource.name}
            </Typography>
            <ErrorResourceLinkList resources={resource.links} />
          </PaperCard>
        </Grid>
      ))}
    </Grid>
  );
}

export default MoreErrorResources;
