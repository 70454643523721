import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import StandardLink from '@/components/atoms/standard-link';

export const StyledList = styled('ul')(({ theme }) => ({
  listStyleType: 'none',
  paddingLeft: theme.spacing(2),
}));

export const StyledLink = styled(StandardLink)(({ theme }) => ({
  color: theme.palette.text.primary,
  textDecorationColor: theme.palette.text.primary,
  '&:hover': {
    textDecorationColor: theme.palette.primary.main,
  },
}));

export const StyledLinkText = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1),
  marginBottom: theme.spacing(1.6),
}));
